import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { Layout, PageTitle, TableList } from '../components'
import { container, padding, bgImage, type } from '../styles/global'
import { parseACF } from '../utils'
import { media } from '../styles/utils'

const PressCitations = (props) => {
    const graphQuery = useStaticQuery(query)
    const data = props?.pageContext?.data || parseACF(graphQuery, 'allWordpressInfopages')
    console.log(data)

    const renderLists = item => {
        return (
            <TableList
                {...item}
                isPressPage={true}
                display_bottom_line={true}
            />
        )
    }

    const renderSectionHeading = item => {
        return (
            <SectionHeading>
                <Subheading>
                    {item.title}
                </Subheading>
            </SectionHeading>
        )
    }

    const renderSections = () => {
        if (!data.sections) return false

        const items = data.sections.map((item, i) => {
            if (item.display_section) {
                return (
                    <Section
                        key={i}
                    >
                        {renderSectionHeading(item)}
                        {renderLists(item)}
                    </Section>
                )
            } else {
                return false
            }
        })

        return (
            <Sections>
                {items}
            </Sections>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            bgColour={'#DDDBC3'}
        >
            <Wrapper>
                <PageTitle
                    title={'Press & Citations'}
                />
                <Container>
                    {renderSections()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Label = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const Icon = styled.img``

// Layout

const Wrapper = styled.div`
    width: 100%;
`

const Container = styled.div`
    ${container}
    ${padding}

    > ${Heading} {
        ${type.heading1}
        text-align: center;
    }
`

// Sections

const Sections = styled.div`
    ${media.phone`
        margin-top: 37px;
    `}
`

const SectionHeading = styled.div``
const Section = styled.div`
    ${media.phone`
        margin-bottom: 60px;
        
        &:not(:first-child) {
            padding-top: 37px;
            border-top: 3px solid black;
        }
    `}

    ${SectionHeading} {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 437.5px;
        max-width: 100%;
        height: 300px;
        margin: 82px auto;
        background-color: black;

        ${media.phone`
            width: 300px;
            height: 210px;
            margin: 0 auto 31px;
        `}

        ${Subheading} {
            ${type.heading1}
            color: #DDDBC3;
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "press-citations" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default PressCitations
